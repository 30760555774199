* {
  box-sizing: border-box;
}

body {
  margin: 0px;
  font-family: "Inter", sans-serif;
  overflow-y: scroll;
}


a {
  text-decoration: none;
}

div {
  font-family: "Inter", sans-serif;
}

button {
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
  background-color: transparent;
  cursor: pointer;
  font-family: "Inter", sans-serif;
}

p {
  margin-top: 0px;
  margin-bottom: 5px;
}

::placeholder {
  color: #575757;
}

:-ms-input-placeholder {
  color: #cecece;
}

::-ms-input-placeholder {
  color: #cecece;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
select:-webkit-autofill,
select:-webkit-autofill:hover {
  overflow: hidden;
  box-sizing: border-box;
  background-color: transparent;
  margin: auto;
  text-align: left;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
}

textarea:focus,
input:focus {
  outline: none;
}

*:focus {
  outline: none;
}

input {
  width: 100%;
  margin-bottom: 5px;
  padding: 10px 10px;
  display: inline-block;
  border: 1px solid #aeb0b1ff;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  color: #515151;
  font-family: "Inter", sans-serif;
  height: 40px;
}

textarea {
  width: 100%;
  margin-bottom: 5px;
  padding: 10px 10px;
  display: inline-block;
  border: 1px solid #aeb0b1ff;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  color: #515151;
  font-family: "Inter", sans-serif;
}

select {
  background-color: #ffffff;
  width: 100%;
  margin-bottom: 5px;
  padding: 10px 10px;
  display: inline-block;
  border: 1px solid #aeb0b1ff;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  color: #515151;
  height: 40px;
  font-family: "Inter", sans-serif;
}

.nav {
  width: 100%;
  height: 55px;
  background-color: rgba(255, 255, 255, 0.603);
  position: fixed;
  top: 0;
  box-shadow: 0px 5px 20px 20px #0000000a;
  z-index: 99;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
}

.nav_limiter {
  width: 980px;
  max-width: 90%;
  margin: auto;
}

.nav_limiter>.nav_header {
  display: inline;
}

.nav_limiter>.nav_header>.nav_title {
  display: inline-block;
  color: rgb(0, 0, 0);
  cursor: pointer;
  padding-top: 5px;
}

.nav_limiter>.nav_links {
  padding-top: 13px;
  display: inline;
  float: right;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.2px;
}

.nav_limiter>.nav_links>a {
  display: inline-block;
  padding: 5px 10px 5px 10px;
  text-decoration: none;
  color: #435893;
  border-radius: 2rem;
  cursor: pointer;
  margin-left: 10px;
}

.nav_limiter>.nav_links>a:hover {
  background-color: #5580cf;
  color: #fff;
  cursor: pointer;
}

@media (max-width : 600px) {
  .nav_limiter>.nav_links {
    font-size: 12px;
  }
}

#active {
  background-color: #284b8c;
  color: #fff;
  cursor: pointer;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.main_container {
  width: 980px;
  max-width: 90%;
  margin: auto;
  margin-top: 70px;
  margin-bottom: 50px;
}

.menu_container {
  display: flex;
  justify-content: center;
  background-color: #284b8c;
  width: 100%;
  border-radius: 10px;
  min-height: 100px;
  padding-top: 23px;
  padding-bottom: 23px;
}

.menu_item {
  display: inline-block;
  background-color: #fff;
  color: #000;
  padding: 20px 40px;
  border-radius: 5px;
  margin: 10px;
  cursor: pointer;
  text-align: center;
}

.menu_item:hover {
  background-color: #5580cf;
  color: #fff;
}

.timeline {
  position: relative;
  margin: 50px auto;
  padding: 40px 0;
  width: 1000px;
  max-width: 100%;
}

.timeline:before {
  content: '';
  position: absolute;
  left: 50%;
  top: 0;
  width: 2px;
  height: 100%;
  background: #c5c5c5;
}

.timeline ul {
  margin: 0;
  padding: 0;
}

.timeline li {
  list-style: none;
  position: relative;
  width: 50%;
  padding: 20px 40px;
  box-sizing: border-box;
}

.timeline li .content {
  padding-bottom: 20px;
}

.timeline li:nth-child(odd) {
  float: right;
  clear: both;
  text-align: left;
}

.timeline li:nth-child(odd):before {
  content: '';
  position: absolute;
  top: 24px;
  left: -4px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #435893;
  box-shadow: 0 0 0 3px #5580cf50;
}

.timeline li:nth-child(even) {
  float: right;
  clear: both;
  text-align: left;
}

.timeline li:nth-child(even):before {
  content: '';
  position: absolute;
  top: 24px;
  left: -4px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #435893;
  box-shadow: 0 0 0 3px #5580cf50;
}

.timeline li h3 {
  margin: 0;
  padding: 0;
  font-weight: 600;
  color: #435893;
}

.timeline li p {
  margin: 10px 0 0;
  padding: 0;
}

.timeline li .time h4 {
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.timeline li:nth-child(odd) .time {
  position: absolute;
  top: 12px;
  left: -165px;
  margin: 0;
  padding: 8px 16px;
  background: #435893;
  color: #fff;
  border-radius: 18px;
  box-shadow: 0 0 0 3px #5580cf50;
}

.timeline li:nth-child(even) .time {
  position: absolute;
  top: 12px;
  left: -165px;
  margin: 0;
  padding: 8px 16px;
  background: #435893;
  color: #fff;
  border-radius: 18px;
  box-shadow: 0 0 0 3px #5580cf50;
}

h1 {
  text-align: center;
  font-size: 40px;
  margin-bottom: 70px;
}

.text_title {
  letter-spacing: 0.3px;
  line-height: 1.3;
  font-size: 14px;
  font-weight: 500;
  color: #354675;
}

.media_item {
  display: inline-block;
  vertical-align: top;
  margin: 5px;
  width: 300px;
  max-width: 90%;
  overflow-x: hidden;
  overflow-y: hidden;
  text-align: center;
}

.media_inside {
  height: calc((100vh - 400px));
  overflow-y: auto;
}

.button_standard {
  padding: 7px;
  text-align: center;
  display: inline-block;
  border-radius: 7px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  background-color: #2196f3;
  color: #ffffff;
}


.footer {
  width: 100%;
  background-color: #284b8c;
  color: white;
  padding-top: 50px;
  padding-bottom: 100px;
}

.footer_limiter {
  width: 980px;
  max-width: 90%;
  margin: auto;
}


.text_inferior {
  font-size: 9px;
  font-weight: 500;
  color: #52606e
}

.button_social {
  display: block;
  text-align: center;
  width: 90px;
  background-color: #2196f3;
  color: #ffffff;
  box-sizing: border-box;
  font-size: 13px;
  padding: 3px 5px 3px 5px;
  border-radius: 7px;
  margin-bottom: 5px;
}

.text_isi {
  font-size: 15px;
  font-weight: 470;
  line-height: 110%;
  letter-spacing: -0.3px;
}